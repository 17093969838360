<template>
    <div class="servico">
        <section class="title-internal">
            <div class="row flex align-center">
                <div class="sm-12">
                    <h1>{{ servico.titulo }}</h1>
                </div>
            </div>
        </section>

        <section class="spacing service">
            <div class="row">
                <div class="sm-12 md-8" v-html="texto"></div>		
                <div class="sm-12 md-4">
                    <div class="info" v-html="servico.snippet" v-if="servico.snippet != ''"></div>
                </div>
            </div>
            <div class="row">
                <div class="sm-12 columns">
                    <img v-for="(imagem, index) in imagens" :src="imagem.src" :alt="imagem.alt" :title="imagem.title" :key="index">
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
	computed: {
		id() {
			return this.$route.params.id;
		},
		slug() {
			return this.$route.params.slug;
		},
		servico() {
			const servicos = this.$store.state.Servicos.servicos;
			return (
				servicos.find(servico => {
					return servico.slug == this.slug;
				}) || {
					titulo: "",
					texto: "",
					snippet: "",
					icone: ""
				}
			);
		},
		texto() {
			if ("undefined" == typeof document) {
				const cheerio = require("cheerio");
				const $ = cheerio.load(this.servico.texto);
				$("img").remove();
				return $.html();
			}
			const tmp = document.createElement("div");
			tmp.innerHTML = this.servico.texto;
			const imgs = tmp.querySelectorAll("img");
			const images = [];
			imgs.forEach(img => {
				try {
					let el = img;
					let parent;
					do {
						parent = el.parentNode;
						parent.removeChild(el);
						if (parent.childNodes.length == 0) {
							el = parent;
						} else {
							el = null;
						}
					} while (el != null && el.parentNode != null);
					//img.parentNode.removeChild(img);
				} catch (e) {}
			});
			return tmp.innerHTML;
		},
		imagens() {
			if ("undefined" == typeof document) {
				const cheerio = require("cheerio");
				const $ = cheerio.load(this.servico.texto);
				const imgs = $("img").map((index, elem) => {
					return {
						src: $(elem)
							.attr("src")
							.replace(/(resize|crop)\/\d+x\d+\//gim, ""),
						alt: $(elem).attr("alt") || "",
						title: $(elem).attr("title") || ""
					};
				});
				return imgs;
			}
			const el = document.createElement("div");
			el.innerHTML = this.servico.texto;
			const imgs = el.querySelectorAll("img");
			const images = [];
			imgs.forEach(img => {
				images.push({
					src: img.src.replace(/(resize|crop)\/\d+x\d+\//gim, ""),
					alt: img.alt || "",
					title: img.title || ""
				});
			});
			return images;
		}
	},
	asyncData({ route, store }) {
		return store.dispatch("Servicos/Load");
	},
	metas({ store, route }) {
		let description;
		let texto;
		let imagem;
		const servicos = store.state.Servicos.servicos;
		const servico = servicos.find(servico => {
			return servico.slug == route.params.slug;
		}) || {
			titulo: "",
			texto: "",
			snippet: "",
			icone: ""
		};
		if ("undefined" == typeof document) {
			const cheerio = require("cheerio");
			const $ = cheerio.load(servico.texto);
			texto = $.text();
			description = $("p")
				.eq(0)
				.text();
			if (0 != $("img").length) {
				imagem = $("img")
					.eq(0)
					.attr("src");
			}
		} else {
			const $texto = jQuery(servico.texto);
			texto = $texto.text();
			description = $texto
				.find("p")
				.eq(0)
				.text();
			if (0 != $texto.find("img").length) {
				imagem = $texto.find("img").attr("src");
			}
		}

		const keywords = [
			...new Set(
				texto
					.replace(/[^\d\w]/gim, " ")
					.replace(/\s+$/gim, "")
					.replace(/^\s+/gim, "")
					.toLowerCase()
					.split(/\s+/gim)
			)
		];

		const metas = {
			title: `${servico.titulo} - Serviços - StudioGT`,
			description: description,
			keywords: keywords.join(",")
		};

		if (imagem) {
			metas["og:image"] = imagem;
		}
		return metas;
	},
	async created() {}
};
</script>

