<template>
  <nav>
    <ul class="menu flex justify-between align-center tablet-column">
      <li data-menu="sobre">
        <router-link to="/sobre" :class="{active: route == 'sobre'}">sobre</router-link>
      </li>
      <li data-menu="equipe">
        <router-link to="/equipe" :class="{active: route == 'equipe'}">equipe</router-link>
      </li>
      <li data-menu="projetos">
        <router-link to="/projetos" :class="{active: route == 'projetos'}">projetos</router-link>
      </li>
      <li data-menu="servicos">
        <router-link to="/servicos" :class="{active: route == 'servicos'}">serviços</router-link>
      </li>
      <li data-menu="clientes">
        <router-link to="/clientes" :class="{active: route == 'clientes'}">clientes</router-link>
      </li>
      <li data-menu="blog">
        <router-link to="/blog" :class="{active: route == 'blog'}">blog</router-link>
      </li>
      <li data-menu="contato">
        <router-link to="/contato" :class="{active: route == 'contato'}">contato</router-link>
      </li>
      <li>
        <a :href="`tel:${telefone.replace(/\D/igm,'')}`" target="_blank">
          <i class="icon-phone-call"></i>
          {{ telefone }}
        </a>
      </li>
      <li>
        <a :href="`https://wa.me/55${whatsapp.replace(/\D/igm,'')}`" target="_blank">
          <i class="icon-whatsapp"></i>
          {{ whatsapp }}
        </a>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  computed: {
    telefone() {
      return this.$store.state.Contato.info.telefone;
    },
    whatsapp() {
      return this.$store.state.Contato.info.whatsapp;
    },
    route() {
      return this.$route.name;
    }
  },
  created() {
    this.$store.dispatch("Contato/Load");
  }
};
</script>
