<template>
    <section class="clients-home">

		<div class="row">
			<div class="sm-12">
				<h4>Alguns clientes atendidos</h4>

				<div class="owl-carousel carousel-clients">					    
					<a :href="cliente.link" :title="cliente.titulo" class="item" v-for="cliente in clientes" v-bind:key="cliente.id">
						<img :src="cliente.logo" :alt="cliente.titulo">
					</a>
				</div>
			</div>
		</div>
		
	</section>
</template>
<script>
//import "owl.carousel";
import "owl.carousel/dist/assets/owl.carousel.css";

export default {
	computed: {
		clientes() {
			return this.$store.state.Clientes.destaques;
		}
	},
	mounted() {
		this.setCarousel();
	},
	async created() {
		//await this.$store.dispatch("Clientes/Load");
		this.setCarousel();
	},
	methods: {
		async setCarousel() {
			if ("undefined" == typeof window) {
				return;
			}
			await import("owl.carousel");
			const $el = jQuery(this.$el).find(".carousel-clients");
			if ($el.is(".owl-loaded")) {
				$el.owlCarousel("destroy");
			}
			$el.owlCarousel({
				autoplay: true,
				dots: false,
				loop: true,
				items: 4,
				autoplayTimeout: 10000,
				responsive: {
					640: {
						items: 6
					},
					1120: {
						items: 9
					}
				}
			});
		}
	}
};
</script>
