import { render, staticRenderFns } from "./PostItem.vue?vue&type=template&id=0ecf11b6&scoped=true&"
import script from "./PostItem.vue?vue&type=script&lang=js&"
export * from "./PostItem.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./PostItem.vue?vue&type=style&index=0&id=0ecf11b6&lang=less&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "0ecf11b6",
  "c8d17e6e"
  
)

component.options.__file = "PostItem.vue"
export default component.exports