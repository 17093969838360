import { render, staticRenderFns } from "./Clientes.vue?vue&type=template&id=2a8137e3&"
import script from "./Clientes.vue?vue&type=script&lang=js&"
export * from "./Clientes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "53214592"
  
)

component.options.__file = "Clientes.vue"
export default component.exports