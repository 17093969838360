<template>
  <footer class="wrap-footer">
    <div class="row">
      <div class="sm-12 md-2 lg-1">
        <h4>Menu</h4>
        <ul>
          <li>
            <router-link to="/sobre">Sobre</router-link>
          </li>
          <li>
            <router-link to="/projetos">Projetos</router-link>
          </li>
          <li>
            <router-link to="/servicos">Serviços</router-link>
          </li>
          <li>
            <router-link to="/blog">Blog</router-link>
          </li>
          <li>
            <router-link to="/contato">Contato</router-link>
          </li>
        </ul>
      </div>
      <div class="sm-12 md-4 lg-3">
        <h4>Onde estamos</h4>
        <a href="http://maps.google.com" target="_blank">
          {{ contato.logradouro }},
          {{ contato.numero }}
          {{ contato.complemento }}
          <br>
          {{ contato.bairro }}
          <br>
          {{ contato.cidade }}/{{ contato.uf }}
          <br>
          Cep {{ contato.cep }}
          <br>
        </a>
      </div>
      <div class="sm-12 md-3 lg-3">
        <h4>Contatos</h4>
        <ul>
          <li>
            <a :href="`tel:${contato.telefone.replace(/\D/igm,'')}`">Fone: {{ contato.telefone }}</a>
          </li>
          <li>
            <a
              :href="`https://wa.me/55${contato.whatsapp.replace(/\D/igm,'')}`"
            >WhatsApp: {{ contato.whatsapp }}</a>
          </li>
          <li>
            <a :href="`mailto:${contato.email}`">{{ contato.email }}</a>
          </li>
        </ul>
      </div>
      <div class="sm-12 md-3 lg-2">
        <h4>Social</h4>
        <ul class="social">
          <li v-for="rede in redes" v-bind:key="rede.id">
            <a :href="rede.link" :title="rede.titulo" target="_blank">
              <i :class="rede.icone"></i>
            </a>
          </li>
        </ul>
      </div>
      <div class="sm-12 md-12 lg-3">
        <div class="logo">
          <img src="@/assets/images/logo-mobile.svg" alt>
        </div>
      </div>
    </div>
  </footer>
</template>
<style lang="less">
</style>
<script>
export default {
  created() {
    this.$store.dispatch("RedesSociais/Load");
    this.$store.dispatch("Contato/Load");
  },
  computed: {
    redes() {
      return this.$store.state.RedesSociais.redes;
    },
    contato() {
      return this.$store.state.Contato.info;
    }
  }
};
</script>
