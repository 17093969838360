<template>
    <div>
        <div class="owl-carousel card-banner visible-md">
            <div class="image-item" v-for="banner in desktop" v-bind:key="banner.id">
                <section>
                    <figure
                        class="image is-3by1"
                        v-bind:style="{'background-image': `url(${banner.imagem})`}"
                    >
                        <img src="@/assets/images/bulma/3by1.png" alt>
                    </figure>
                    <div class="text">
                        <div class="row">
                            <div class="sm-12">
                                <h1>{{ banner.titulo }}</h1>
                                <h3>{{ banner.subtitulo }}</h3>
                                <router-link
                                    :to="banner.link"
                                    v-if="banner.tipo_link == 'Interno'"
                                    :style="{'background-color': banner.cor_link}"
                                >{{ banner.titulo_link }}</router-link>
                                <a
                                    :href="banner.link"
                                    target="_blank"
                                    v-if="banner.tipo_link == 'Externo'"
                                    :style="{'background-color': banner.cor_link}"
                                >{{ banner.titulo_link}}</a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div class="owl-carousel card-banner hidden-md">
            <div class="image-item" v-for="banner in mobile" v-bind:key="banner.id">
                <figure
                    class="image is-1by1"
                    v-bind:style="{'background-image': `url(${banner.imagem})`}"
                >
                    <img src="@/assets/images/bulma/1by1.png" alt>
                </figure>
            </div>
        </div>
    </div>
</template>
<script>
//import "owl.carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import OwlCarousel from "@/components/owl-carousel.vue";

export default {
    components: {
        OwlCarousel
    },
    data() {
        return {
            carouselOptions: {
                animateOut: "fadeOut",
                animateIn: "fadeIn",
                autoplay: true,
                dots: false,
                items: 1,
                loop: true,
                autoplayTimeout: 10000
            }
        };
    },
    computed: {
        banners() {
            const banners = this.$store.state.Banners.primarios;
            return banners;
        },
        desktop() {
            return this.banners.filter(banner => {
                return banner.desktop;
            });
        },
        mobile() {
            return this.banners.filter(banner => {
                return banner.mobile;
            });
        }
    },
    methods: {
        setCarousel() {
            if ("undefined" == typeof window) {
                return;
            }
            import("owl.carousel").then(() => {
                const $els = jQuery(this.$el).find(".card-banner");
                $els.each(function() {
                    var $el = jQuery(this);
                    if ($el.is(".owl-loaded")) {
                        $el.owlCarousel("destroy");
                    }
                    $el.owlCarousel({
                        animateOut: "fadeOut",
                        animateIn: "fadeIn",
                        autoplay: true,
                        dots: false,
                        items: 1,
                        loop: true,
                        autoplayTimeout: 10000
                    });
                });
            });
        }
    },
    mounted() {
        this.setCarousel();
    },
    async created() {
        //await this.$store.dispatch("Banners/Load");
        this.setCarousel();
    }
};
</script>
