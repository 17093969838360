<template>
    <div class="blog">
        <section class="title-internal">
            <div class="row">
                <div class="sm-12">
                    <h1>Blog</h1>
                </div>
            </div>
        </section>

        <section class="spacing">
            <div class="row">
                <div class="sm-12 md-8">
                    <ul class="blog-list" v-if="posts.length!=0">
                        <li v-for="post in posts" v-bind:key="post.id">
                            <PostItem :post="post" />
                        </li>
                    </ul>
                    <ul class="blog-list" v-if="posts.length == 0 && !carregando">
                        <li>
                            <p>Nenhum post encontrado.</p>
                        </li>
                    </ul>

                    <Paginacao />
                </div>
                <div class="sm-12 md-4 offset-lg-1 lg-3">
                    <Widgets />
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import Paginacao from "@/views/blog/Paginacao.vue";
import Widgets from "@/views/blog/Widgets.vue";

import PostItem from "@/views/blog/PostItem.vue";

const loadData = async ({ store, route }) => {
    try {
        const slug = route.params.slug || "";
        const search = route.query.q || "";
        const ano = route.params.ano | 0;
        const mes = route.params.mes | 0;
        await store.dispatch("Blog/setCategoria", slug);
        await store.dispatch("Blog/setAno", ano);
        await store.dispatch("Blog/setMes", mes);
        await store.dispatch("Blog/setSearch", search);
        await store.dispatch("Blog/LoadCategorias");
        await store.dispatch("Blog/Load");
    } catch (err) {
        console.error(err);
    }
};

export default {
    computed: {
        carregando() {
            return this.$store.state.Blog.loading;
        },
        posts() {
            return this.$store.state.Blog.posts;
        },
        slug() {
            return this.$route.params.slug || "";
        },
        ano() {
            return this.$route.params.ano | 0;
        },
        mes() {
            return this.$route.params.mes | 0;
        },
        search() {
            return this.$route.query.q || "";
        }
    },
    components: {
        Paginacao,
        Widgets,
        PostItem
    },
    methods: {
        /*
		async loadData() {
			const slug = this.slug || "";
			const search = this.search || "";
			const ano = this.ano | 0;
			const mes = this.mes | 0;
			await this.$store.dispatch("Blog/setCategoria", slug);
			await this.$store.dispatch("Blog/setAno", ano);
			await this.$store.dispatch("Blog/setMes", mes);
			await this.$store.dispatch("Blog/setSearch", search);
			await this.$store.dispatch("Blog/Load");
		}
		*/
    },
    async asyncData({ store, route }) {
        return loadData({ store, route });
    },
    async updated() {
        //this.reload();
    },
    async mounted() {
        //this.loadData();
    },
    watch: {
        //$route: "loadData"
        $route: function() {
            loadData({
                store: this.$store,
                route: this.$route
            });
        }
    }
};
</script>
