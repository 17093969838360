<template>
    <article>
        <router-link :to="`/post/${post.slug}`">
            <div class="row flex flex-wrap">
                <div class="sm-12 md-4">
                    <figure
                        class="image is-4by3"
                        :style="{'background-image': `url(${img})`,'background-color': post.imagem.averageColor}"
                    >
                        <img src="@/assets/images/bulma/4by3.png" alt />
                    </figure>
                </div>
                <div class="sm-12 md-8">
                    <header>
                        <h2>{{ post.titulo }}</h2>
                        <p>{{ resumo }}</p>
                        <span>{{ data }}</span>
                    </header>
                </div>
            </div>
        </router-link>
    </article>
</template>
<style lang="less" scoped="true">
figure.image {
    transition: background-image 0.5s;
}
</style>

<script>
export default {
    props: ["post"],
    data() {
        return {
            img:
                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
        };
    },
    computed: {
        data() {
            if (!this.post.data) return "";
            const meses = [
                "",
                "janeiro",
                "fevereiro",
                "março",
                "abril",
                "maio",
                "junho",
                "julho",
                "agosto",
                "setembro",
                "outubro",
                "novembro",
                "dezembro"
            ];
            let ano, mes, dia;
            [ano, mes, dia] = this.post.data.split("-");
            mes = meses[mes | 0];
            return `${dia} de ${mes} de ${ano}`;
        },
        resumo() {
            if ("undefined" == typeof document) {
                return "";
            }
            const el = document.createElement("div");
            el.innerHTML = this.post.texto;
            const paragraphs = el.querySelectorAll("p");

            let texts = [];
            paragraphs.forEach(p => {
                texts.push(p);
            });
            texts = texts
                .map(p => {
                    return p.innerText;
                })
                .filter(p => {
                    return p != "";
                });

            return texts.shift();
        }
    },
    created() {
        if ("undefined" != typeof window) {
            const img = new Image();
            img.onload = () => {
                this.img = this.post.imagem.src;
            };
            img.src = this.post.imagem.src;
        } else {
            this.img = this.post.imagem.src;
        }
    }
};
</script>
