<template>
    <ul class="pagination clearfix" role="navigation">
        <!--
		
		<li><a>anterior</a></li>
		
		<li v-for="page in [1,2,3,4,5]" v-bind:key="page"><a class="current" >{{ page }}</a></li>
		
		<li><a href="" class="disabled">próximo</a></li>
        -->

        <li v-if="carregando">
            <a class="disabled">Carregando...</a>
        </li>

        <li>
            <a v-on:click="carregarMais" v-show="hadMore && !carregando">Carregar Mais</a>
        </li>
    </ul>
</template>
<style lang="less" scoped="true">
ul.pagination {
    li {
        a {
            width: auto;
        }
    }
}
</style>

<script>
export default {
    computed: {
        carregando() {
            return this.$store.state.Blog.loading || false;
        },
        hadMore() {
            try {
                return this.$store.state.Blog.pageInfo.hasNextPage || false;
            } catch (err) {
                return false;
            }
        }
    },
    methods: {
        carregarMais() {
            this.$store.dispatch("Blog/LoadMore");
        }
    }
};
</script>
