import gql from "graphql-tag";
import apollo from "@/graphql";

import config from "@/config";
const endpoint = config.endpoint;

const state = {
	info: {
		email: "",
		telefone: "",
		whatsapp: "",
		cep: "",
		logradouro: "",
		numero: "",
		complemento: "",
		bairro: "",
		cidade: "",
		uf: ""
	},
	loading: false
};

const actions = {
	async Load({ commit }) {
		commit("setLoading", true);

		const resp = await apollo.query({
			query: gql`
				query InfoContato {
					contato {
						email
						telefone
						whatsapp
						cep
						logradouro
						numero
						complemento
						bairro
						cidade
						uf
					}
				}
			`,
			variables: {}
		});

		commit("setInfo", resp.data.contato);

		commit("setLoading", false);
	},
	async Save({ commit }, contato) {
		commit("setLoading", true);
		try {
			const result = await apollo.mutate({
				mutation: gql`
					mutation addContato($contato: ContactInput) {
						contato: addContato(contato: $contato) {
							id
						}
					}
				`,
				variables: {
					contato: contato
				}
			});
			commit("setLoading", false);
			//alert("Formulário enviado com sucesso.");
		} catch (err) {
			commit("setLoading", false);
			if (err.graphQLErrors) {
				console.log(err.graphQLErrors);
				let error;
				[error] = err.graphQLErrors;
				//alert(error.message);
				throw new Error(error.message);
			} else {
				//alert("Não foi possível enviar o formulário.");
				console.log(err);
				throw new Error("Não foi possível enviar o formulário.");
			}
		}
	}
};
const mutations = {
	setInfo(State, info) {
		State.info = info;
	},
	setLoading(State, loading) {
		State.loading = loading;
	}
};
const getters = {};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
};
