<template>
    <div class="clientes">
        <section class="title-internal">
            <div class="row">
                <div class="sm-12">
                    <h1>Clientes</h1>
                </div>
            </div>
        </section>

        <section class="spacing clients">

            <div class="row">
                <div class="sm-12">
                    <ul class="sm-grid-2 md-grid-4 lg-grid-6">
                        <li v-for="cliente in clientes" v-bind:key="cliente.id">
                            <a :href="cliente.link" :title="cliente.titulo" class="item" target="_blank">
                                <img :src="cliente.logo" :alt="cliente.titulo">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

        </section>
    </div>
</template>
<script>
export default {
	computed: {
		clientes() {
			return this.$store.state.Clientes.todos;
		}
	},
	asyncData({ store, route }) {
		return store.dispatch("Clientes/Load");
	}
};
</script>
