import Vue from "vue";
import Router from "vue-router";
import NotFound from "./views/404.vue";
import Home from "./views/Home.vue";
import Sobre from "./views/Sobre.vue";
import Servicos from "./views/Servicos.vue";
import Servico from "./views/Servico.vue";
import Clientes from "./views/Clientes.vue";
import Equipe from "./views/Equipe.vue";
import Projetos from "./views/Projetos.vue";
import Blog from "./views/Blog.vue";
import Post from "./views/Post.vue";
import Contato from "./views/Contato.vue";
import PaginaAvulsa from './views/pagina-avulsa/index.vue';

Vue.use(Router);

export function createRouter() {
	return new Router({
		mode: "history",
		routes: [
			{
				path: "/",
				alias: "/home",
				name: "home",
				component: Home
			},
			{
				path: "/sobre",
				name: "sobre",
				component: Sobre
			},
			{
				path: "/clientes",
				name: "clientes",
				component: Clientes
			},
			{
				path: "/servicos",
				name: "servicos",
				component: Servicos
			},
			{
				path: "/servico/:slug",
				name: "servico",
				component: Servico
			},
			{
				path: "/equipe",
				name: "equipe",
				component: Equipe
			},
			{
				path: "/projetos/:slug",
				name: "projetos-interna",
				component: Projetos
			},
			{
				path: "/projetos",
				name: "projetos",
				component: Projetos
			},

			{
				path: "/blog/search",
				name: "blogsearch",
				component: Blog,
				props: route => ({ query: route.query.q })
			},
			{
				path: "/blog/:ano/:mes",
				name: "blogarchive",
				component: Blog
			},
			{
				path: "/blog/:slug",
				name: "blogcategory",
				component: Blog
			},
			{
				path: "/blog",
				name: "blog",
				component: Blog
			},
			{
				path: "/post/:slug",
				name: "post",
				component: Post
			},
			{
				path: "/contato",
				name: "contato",
				component: Contato
			},
			{
				path: '/:slug',
				name: "paginas",
				component: PaginaAvulsa
			},
			{
				path: "*",
				name: "404",
				component: NotFound
			}
		]
	});
}
