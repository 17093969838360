import gql from "graphql-tag";
import apollo from "@/graphql";

import config from "@/config";
const endpoint = config.endpoint;

const state = {
	esquerda: "",
	direita: "",
	timeline: [],
	loading: false
};
const actions = {
	async Load({ commit }) {
		commit("setLoading", true);
		const result = await apollo.query({
			query: gql`
				query Sobre {
					sobre {
						texto_esquerda
						texto_direita
					}
					timeline {
						imagem
						ano
						texto
					}
				}
			`,
			variables: {}
		});

		const timeline = result.data.timeline.map(record => {
			record.full_imagem = `${endpoint}/media/resize/500x999/${
				record.imagem
			}`;
			return record;
		});

		commit("setTextoEsquerda", result.data.sobre.texto_esquerda);
		commit("setTextoDireita", result.data.sobre.texto_direita);
		commit("setTimeline", timeline);

		commit("setLoading", false);
	}
};
const mutations = {
	setTextoEsquerda(State, texto) {
		State.esquerda = texto;
	},
	setTextoDireita(State, texto) {
		State.direita = texto;
	},
	setTimeline(State, timeline) {
		State.timeline = timeline;
	},
	setLoading(State, loading) {
		State.loading = loading;
	}
};
const getters = {};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
};
