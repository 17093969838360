<template>
  <div class="post">
    <section class="title-internal">
      <div class="row">
        <div class="sm-12">
          <h1>{{ post.titulo }}</h1>
        </div>
      </div>
    </section>

    <section class="spacing">
      <div class="row">
        <div class="sm-12 md-8">
          <span v-html="post.texto"></span>
          <owl-carousel class="carousel-blog" :options="carouselOptions">
            <div class="item" v-for="imagem in post.imagens" v-bind:key="imagem.id">
              <a :href="imagem.src_hd" class="fancybox" rel="group">
                <img
                  :src="imagem.src"
                  :alt="imagem.alt"
                  :title="imagem.title"
                  :style="{'background-color': imagem.averageColor}"
                >
              </a>
            </div>
          </owl-carousel>
        </div>
        <div class="sm-12 md-4">
          <div class="info" v-if="post.snippet != '' && post.snippet" v-html="post.snippet"></div>
          <Widgets/>
        </div>
      </div>
    </section>
  </div>
</template>
<style lang="less">
.carousel-blog {
  .owl-stage {
    width: 100% !important;
  }
}
.post {
  .title-internal {
    h1 {
      font-weight: 700;
    }
  }
}
</style>

<script>
//import "owl.carousel";
import OwlCarousel from "@/components/owl-carousel";
import "fancybox/dist/css/jquery.fancybox.css";
//import fancybox from "fancybox";
//fancybox(jQuery);
//import cheerio from "cheerio";
import Widgets from "@/views/blog/Widgets.vue";

export default {
  data() {
    return {
      carouselOptions: {
        autoplay: true,
        dots: false,
        //loop: true,
        items: 3,
        autoWidth: true,
        autoplayTimeout: 10000
      }
    };
  },
  components: {
    Widgets,
    OwlCarousel
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
    post() {
      return this.$store.state.Blog.post || {};
    }
  },
  methods: {
    async setFancybox() {
      if ("undefined" == typeof window) {
        return;
      }
      await import("@/util/fancybox");
      this.$nextTick(() => {
        jQuery(this.$el).off("click", ".fancybox");
        jQuery(this.$el)
          .find(".fancybox")
          .fancybox({
            helpers: {
              title: {
                type: "inside"
              },
              overlay: {
                locked: false
              }
            },
            live: true
          });
        jQuery(this.$el).on("click", ".fancybox", e => {
          e.preventDefault();
          jQuery(this).trigger("click.fb");
        });
      });
    }
  },
  updated() {
    this.setFancybox();
  },
  mounted() {
    this.setFancybox();
  },
  metas({ store, route }) {
    const post = store.state.Blog.Posts[route.params.slug] || {};
    let description;
    let texto;
    if ("undefined" == typeof window) {
      //const cheerio = await import("cheerio");
      const cheerio = require("cheerio");
      const $ = cheerio.load(post.texto);
      texto = $.text();
      description = $("p")
        .eq(0)
        .text();
    } else {
      texto = jQuery(post.texto).text();
      description = jQuery(post.texto)
        .find("p")
        .eq(0)
        .text();
    }

    const keywords = [
      ...new Set(
        texto
          .replace(/[^\d\w]/gim, " ")
          .replace(/\s+$/gim, "")
          .replace(/^\s+/gim, "")
          .toLowerCase()
          .split(/\s+/gim)
      )
    ];

    const metas = {
      title: `${post.titulo} - Blog - StudioGT`,
      description: description,
      keywords: keywords.join(",")
    };
    const imagens = post.imagens || [];
    if (0 != imagens.length) {
      let imagem;
      [imagem] = imagens;
      metas["og:image"] = imagem.src;
    }
    return metas;
  },
  async asyncData({ store, route }) {
    await store.dispatch("Blog/LoadPost", route.params.slug);
    await store.dispatch("Blog/LoadCategorias");

    /*
		if ("undefined" == typeof document) {
			return;
		}
		*/
  },
  async beforeMount() {
    //this.setFancybox();
    //a.dispatch("Blog/LoadPost", this.slug);
  }
};
</script>
