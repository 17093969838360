<template>
    <div class="equipe">
        <section class="title-internal">
            <div class="row">
                <div class="sm-12">
                    <h1>Equipe</h1>
                </div>
            </div>
        </section>

        <section class="spacing team">
            <div class="row">
                <div class="sm-12 md-6 lg-4">
                    <h2 v-html="texto"></h2>
                </div>
                <div class="sm-12 md-6 offset-lg-2">
                    <ul class="sm-grid-2">
                        <Membro :membro="membro" v-for="membro in membros.slice(0,2)" v-bind:key="membro.id" />
                    </ul>
                </div>
            </div>

            <div class="row">
                <div class="sm-12">
                    <ul class="sm-grid-2 md-grid-3 lg-grid-4">
                        <Membro :membro="membro" v-for="membro in membros.slice(2)" v-bind:key="membro.id" />
                    </ul>
                </div>
            </div>
        </section>

    </div>
</template>
<script>
import Membro from "@/views/equipe/Membro.vue";

export default {
	components: {
		Membro
	},
	computed: {
		texto() {
			return this.$store.state.Equipe.equipe.texto;
		},
		membros() {
			return this.$store.state.Equipe.membros || [];
		}
	},
	asyncData({ store, route }) {
		return store.dispatch("Equipe/Load");
	}
};
</script>
