<template>
  <section class="jobs-home">
    <div class="row">
      <div class="sm-12 flex justify-between align-center tablet-column title">
        <div>
          <h2>
            Trabalhos
            <strong>personalizados</strong>
          </h2>
          <h3>Desenvolvemos sob medida para seu negócio.</h3>
        </div>
        <a href="contato" class="default-button translate">Entre em contato</a>
      </div>
    </div>
    <div class="row">
      <div class="sm-12">
        <div class="owl-carousel jobs-items">
          <div class="item" v-for="banner in banners" v-bind:key="banner.id">
            <router-link
              v-if="banner.tipo == 'Interno' && banner.posicao_link == 'Topo'"
              :to="banner.link"
              class="text"
              :style="{'background-color': banner.cor_link}"
            >
              <h3>{{ banner.titulo_link }}</h3>
            </router-link>
            <a
              v-else-if="banner.posicao_link == 'Topo'"
              :href="banner.link"
              class="text"
              target="_blank"
              :style="{'background-color': banner.cor_link}"
            >
              <h3>{{ banner.titulo_link }}</h3>
            </a>

            <div
              :style="{'margin-bottom': `${banner.posicao_link == 'Baixo' ? '16px' : '0px' }`,'margin-top': `${banner.posicao_link == 'Topo' ? '16px' : '0px' }`}"
            >
              <router-link v-if="banner.tipo == 'Interno'" :to="banner.link"></router-link>
              <a
                v-else
                :href="banner.link"
                target="_blank"
                :class="{disabled: banner.link == 'javascript:void(0)'}"
              >
                <figure
                  v-if="banner.titulo_link == ''"
                  class="image is-3by4"
                  v-bind:style="{'background-image': `url(${banner.imagem})`}"
                >
                  <img src="@/assets/images/bulma/3by4.png" alt>
                </figure>
                <figure
                  v-else
                  class="image is-5by4"
                  v-bind:style="{'background-image': `url(${banner.imagem})`}"
                >
                  <img src="@/assets/images/bulma/5by4.png" alt>
                </figure>
              </a>
            </div>

            <router-link
              v-if="banner.tipo == 'Interno' && banner.posicao_link == 'Baixo'"
              :to="banner.link"
              class="text"
              :style="{'background-color': banner.cor_link}"
            >
              <h3>{{ banner.titulo_link }}</h3>
            </router-link>
            <a
              v-else-if="banner.posicao_link == 'Baixo'"
              :href="banner.link"
              class="text"
              target="_blank"
              :style="{'background-color': banner.cor_link}"
            >
              <h3>{{ banner.titulo_link }}</h3>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
//import "owl.carousel";
import "owl.carousel/dist/assets/owl.carousel.css";

import job1 from "@/assets/images/job-1.jpg";
import job2 from "@/assets/images/job-2.jpg";

export default {
  computed: {
    banners() {
      return this.$store.state.Banners.secundarios;
    }
  },
  methods: {
    setCarousel() {
      if ("undefined" == typeof window) {
        return;
      }
      import("owl.carousel").then(() => {
        const $el = jQuery(this.$el).find(".jobs-items");
        if ($el.is(".owl-loaded")) {
          $el.owlCarousel("destroy");
        }
        $el.owlCarousel({
          autoplay: true,
          dots: false,
          items: 2,
          loop: true,
          autoplayTimeout: 10000,
          responsive: {
            640: {
              items: 3
            },
            1120: {
              items: 4
            }
          }
        });
      });
    }
  },
  mounted() {
    this.setCarousel();
  },
  async created() {
    //await this.$store.dispatch("Banners/Load");
    this.setCarousel();
  }
};
</script>

