<template>
    <header class="visible-lg wrap-header">
        <div class="row">
            <div class="lg-3">
                <Logo />
            </div>
            <div class="lg-8 offset-lg-1">
                <Menu />
            </div>
        </div>
    </header>
</template>
<script>
import Logo from "@/views/layout/Logo.vue";
import Menu from "@/views/layout/Menu.vue";
export default {
	components: {
		Logo,
		Menu
	}
};
</script>

