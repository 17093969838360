<template>
    <div v-bind:class="classe">
        <slot></slot>
    </div>
</template>
<style lang="less" >
.owl-stage {
	/*width: 100% !important;*/
}
</style>

<script>
import "owl.carousel/dist/assets/owl.carousel.css";
export default {
	props: ["class", "options"],
	data() {
		return {
			widget: null
		};
	},
	computed: {
		classe() {
			return `owl-carousel`;
		}
	},
	methods: {
		setOwlCarousel() {
			if ("undefined" == typeof window) {
				return;
			}
			import("owl.carousel").then(() => {
				this.$nextTick(() => {
					if ($(".owl-carousel").is(".owl-loaded")) {
						$(".owl-carousel").owlCarousel("destroy");
					}

					jQuery(".owl-carousel").owlCarousel(this.options);
				});
			});
		}
	},
	updated() {
		this.setOwlCarousel();
	},
	mounted() {
		this.setOwlCarousel();
	},
	created() {
		if ("undefined" == typeof window) {
			return;
		}
		this.setOwlCarousel();
		jQuery(window).resize(() => {
			this.setOwlCarousel();
		});

		this.$nextTick(() => {
			jQuery(window).trigger("resize");
		});
	},
	beforeDestroy() {}
};
</script>
