import gql from "graphql-tag";
import apollo from "@/graphql";

import config from "@/config";
const endpoint = config.endpoint;

const state = {
	metas: [],
	constantes: {},
	loading: false
};
const actions = {
	async Load({ commit }) {
		commit("setLoading", true);
		const result = await apollo.query({
			query: gql`
				query MetaTags {
					metatags {
						url
						name
						content
						ordenamento
					}
					constantes {
						chave
						valor
					}
				}
			`,
			variables: {}
		});
		result.data.metatags.sort((m1, m2) => {
			return m2.ordenamento - m1.ordenamento;
		});

		commit("setMetaTags", result.data.metatags);

		let constantes = result.data.constantes.reduce(
			(constantes, constante) => {
				constantes[constante.chave] = constante.valor;
				return constantes;
			},
			{}
		);

		commit("setConstantes", constantes);

		commit("setLoading", false);
	},
	Update({ commit }, metas) {
		if (metas.title && !metas["og:title"]) {
			metas["og:title"] = metas.title;
		}

		if (metas.description && !metas["og:description"]) {
			metas["og:description"] = metas.description;
		}

		if ("undefined" != typeof document) {
			metas["og:url"] = location.href;
			Array.from(
				document.querySelectorAll("[data-vue-router-controlled]")
			).map(el => {
				el.parentNode.removeChild(el);
			});
			Object.keys(metas).forEach(key => {
				if (key == "title") {
					document.title = metas[key];
				} else {
					const tag = document.createElement("meta");
					tag.setAttribute("name", key);
					tag.setAttribute("content", metas[key]);
					tag.setAttribute("data-vue-router-controlled", "");
					document.head.appendChild(tag);
				}
			});
		} else {
			/*
			Object.keys(metas).forEach(key => {
				if (key == "title") {
					document.title = metas[key];
				} else {
					const tag = document.createElement("meta");
					tag.setAttribute("name", key);
					tag.setAttribute("content", metas[key]);
					tag.setAttribute("data-vue-router-controlled", "");
					document.head.appendChild(tag);
				}
			});
			*/
		}
	}
};
const mutations = {
	setMetaTags(State, metas) {
		State.metas = metas;
	},
	setLoading(State, loading) {
		State.loading = loading;
	},
	setConstantes(State, constantes) {
		State.constantes = constantes;
	}
};
const getters = {};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
};
