import Vue from "vue";
import Vuex from "vuex";

import Banners from "@/stores/Banners";
import Clientes from "@/stores/Clientes";
import RedesSociais from "@/stores/RedesSociais";
import Equipe from "@/stores/Equipe";
import Projetos from "@/stores/Projetos";
import Servicos from "@/stores/Servicos";
import Blog from "@/stores/Blog";
import Contato from "@/stores/Contato";
import MetaTags from "@/stores/MetaTags";
import BoasPraticas from "@/stores/BoasPraticas";
import Sobre from "@/stores/Sobre";
import PaginaAvulsa from "@/stores/PaginaAvulsa";

Vue.use(Vuex);

export function createStore() {
	return new Vuex.Store({
		state: {
			menu: {
				opened: false
			}
		},
		mutations: {
			toggleMenu(State) {
				State.menu.opened = !State.menu.opened;
			},
			closeMenu(State) {
				State.menu.opened = false;
			}
		},
		actions: {
			toggleMenu({ commit }) {
				commit("toggleMenu");
			},
			closeMenu({ commit }) {
				commit("closeMenu");
			}
		},
		modules: {
			Banners,
			Clientes,
			RedesSociais,
			Equipe,
			Projetos,
			Servicos,
			Blog,
			Contato,
			MetaTags,
			BoasPraticas,
			Sobre,
			PaginaAvulsa
		}
	});
}
