import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { persistCache } from "apollo-cache-persist";
import fetch from "cross-fetch";

const cache = new InMemoryCache();

/*
persistCache({
	cache,
	storage: window.localStorage
});
*/

import config from "@/config";
const endpoint = config.endpoint;

const customFetch = function (uri, options) {

	console.log(options.body);

	return fetch(uri, options);
};

const defaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'ignore',
	},
	query: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all',
	},
}

export default new ApolloClient({
	// Provide the URL to the API server.
	link: new HttpLink({ uri: `${endpoint}/api/graphql`, fetch: fetch }),
	// Using a cache for blazingly
	// fast subsequent queries.
	cache: cache,
	defaultOptions
});
