import { render, staticRenderFns } from "./Servico.vue?vue&type=template&id=646f5edc&"
import script from "./Servico.vue?vue&type=script&lang=js&"
export * from "./Servico.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "4b70bce8"
  
)

component.options.__file = "Servico.vue"
export default component.exports