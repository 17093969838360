<template>
	<div class="home">
		<Banner />
		<Jobs />
		<Tech />
		<Clientes />
	</div>
</template>

<script>
// @ is an alias to /src

import Banner from "@/views/home/Banner.vue";
import Jobs from "@/views/home/Jobs.vue";
import Tech from "@/views/home/Tech.vue";
import Clientes from "@/views/home/Clientes.vue";

export default {
	name: "home",
	components: {
		Banner,
		Jobs,
		Tech,
		Clientes
	},
	asyncData({ store, route }) {
		return Promise.all([
			store.dispatch("Banners/Load"),
			store.dispatch("Clientes/Load"),
			//store.dispatch("Banners/Load"),
			store.dispatch("BoasPraticas/Load")
		]);
	}
};
</script>
