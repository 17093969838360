import gql from "graphql-tag";
import apollo from "@/graphql";

import config from "@/config";
const endpoint = config.endpoint;

const state = {
	destaques: [],
	todos: [],
	loading: false
};
const actions = {
	async Load({ commit }) {
		commit("setLoading", true);
		const result = await apollo.query({
			query: gql`
				query Clientes {
					clientes {
						id
						logo
						titulo
						link
						destaque
						ordenamento
					}
				}
			`,
			variables: {}
		});
		result.data.clientes.sort((c1, c2) => {
			if (c2.destaque == c1.destaque) {
				return c2.ordenamento - c1.ordenamento;
			}
			return c2.destaque - c1.destaque;
		});

		const clientes = result.data.clientes.map(cliente => {
			cliente.logo = `${endpoint}/media/${cliente.logo}`;
			cliente.logo = cliente.logo.replace(
				/^http[s]:\/\/.+(http[s]?:\/\/.+\.(jpg|jpeg|png|gif))/gim,
				"$1"
			);
			return cliente;
		});

		const destaques = clientes.filter(cliente => {
			return cliente.destaque;
		});

		commit("setClientes", clientes);
		commit("setDestaques", destaques);

		commit("setLoading", false);
	}
};
const mutations = {
	setClientes(State, clientes) {
		State.todos = clientes;
	},
	setDestaques(State, destaques) {
		State.destaques = destaques;
	},
	setLoading(State, loading) {
		State.loading = loading;
	}
};
const getters = {};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
};
