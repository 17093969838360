import gql from "graphql-tag";
import apollo from "@/graphql";

import config from "@/config";
const endpoint = config.endpoint;

const state = {	    
    paginas: [],
    Paginas: {},
	pagina: {},
	loading: false,
};


const paginasQuery = gql`
	query LoadPagina {
		paginas {
            id
            slug
            titulo
            texto
        }
	}
`;

const actions = {
	async LoadPagina({ commit, state }, slug) {
		commit("setLoading", true);
		commit("setPagina", state.Paginas[slug] || {});
		const result = await apollo.query({
			query: gql`
				query loadPagina($slug: String) {
					pagina(slug: $slug) {
						id
						slug
						titulo
						texto
					}
				}
			`,
			variables: {
				slug: slug
			}
		});

		const pagina = result.data.pagina;

		commit("setPagina", pagina);

		commit("setLoading", false);
	},
	async Load({ commit, state }) {
		commit("setLoading", true);
		const result = await apollo.query({
			query: paginasQuery,
			variables: {
			}
		});		

		const paginas = result.data.paginas;

		commit("setPaginas", paginas);

		commit("setLoading", false);
	},
	setPagina({ commit }, pagina) {
		commit("setPagina", pagina);
	}
};

const mutations = {
	
	setPaginas(State, paginas) {
		State.paginas = paginas;
        console.log({ paginas })
		paginas.forEach(pagina => {
			State.Paginas[pagina.slug] = pagina;
		});
	},
	setLoading(State, loading) {
		State.loading = loading;
	},
	setPagina(State, pagina) {
		State.Paginas[pagina.slug] = pagina;
		State.pagina = pagina;
	}
};
const getters = {};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
};
