import gql from "graphql-tag";
import apollo from "@/graphql";

import config from "@/config";
const endpoint = config.endpoint;

const state = {
	servicos: [],
	loading: false
};
const actions = {
	async Load({ commit }) {
		commit("setLoading", true);
		const result = await apollo.query({
			query: gql`
				query Servicos {
					servicos {
						id
						slug
						icone
						titulo
						texto
						snippet
						ordenamento
					}
				}
			`,
			variables: {}
		});

		result.data.servicos.sort((s1, s2) => {
			return s2.ordenamento - s1.ordenamento;
		});

		const servicos = result.data.servicos.map(servico => {
			servico.icone = `${endpoint}/media/${servico.icone}`;
			servico.icone = servico.icone.replace(
				/^http[s]:\/\/.+(http[s]?:\/\/.+\.(jpg|jpeg|png|gif))/gim,
				"$1"
			);
			return servico;
		});

		commit("setServicos", servicos);

		commit("setLoading", false);
	}
};
const mutations = {
	setServicos(State, servicos) {
		State.servicos = servicos;
	},
	setLoading(State, loading) {
		State.loading = loading;
	}
};
const getters = {};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
};
