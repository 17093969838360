import { render, staticRenderFns } from "./Clientes.vue?vue&type=template&id=5970753d&"
import script from "./Clientes.vue?vue&type=script&lang=js&"
export * from "./Clientes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "700fe240"
  
)

component.options.__file = "Clientes.vue"
export default component.exports