<template>
    <div class="projetos">
        <section class="title-internal">
            <div class="row">
                <div class="sm-12">
                    <h1>Nossos projetos</h1>
                </div>
            </div>
        </section>

        <section class="spacing">
            <div class="row">
                <div class="sm-12">
                    <ul class="filters">
                        <li v-on:click="filtrar({id:0, titulo: 'Todos'})">Todos</li>
                        <li
                            data-filter="todos"
                            v-for="categoria in categorias"
                            v-bind:key="categoria.id"
                            v-on:click="filtrar(categoria)"
                            :class="{active: categoria.id == id}"
                        >{{ categoria.titulo }}</li>
                    </ul>
                    <div class="isotope">
                        <div class="item-sizer"></div>
                        <isotope
                            ref="isotope"
                            :options="options"
                            :list="projetos"
                            @filter="filterOption=arguments[0]"
                        >
                            <div class="item" v-for="projeto in projetos" v-bind:key="projeto.id">
                                <figure
                                    class="image is-4by3"
                                    :style="{'background-image': `url(${projeto.imagem})`}"
                                >
                                    <img src="@/assets/images/bulma/4by3.png" alt />
                                    <a :href="projeto.imagem_hd" class="see fancybox">
                                        <i class="icon-plus"></i>
                                    </a>
                                    <a :href="projeto.link" target="_blank" class="link">
                                        <i class="icon-chain"></i>
                                    </a>
                                </figure>
                            </div>
                        </isotope>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
//import isotope from "vueisotope";

import "fancybox/dist/css/jquery.fancybox.css";
//import fancybox from "fancybox";
//fancybox(jQuery);
/*
let components = {};
if ("undefined" != typeof window) {
	components.isotope = import("vueisotope");
}
*/
export default {
    //components: components,
    data() {
        return {
            /*
			categoria: {
                id: 0,
                titulo: "Todos",
                projetos: []
			},
			*/
            //projetos: [],
            filterOption: "all",
            sortOption: "ordenamento",
            options: {
                sortBy: "ordenamento",
                getSortData: {
                    ordenamento: function(item) {
                        return -1 * item.ordenamento;
                    }
                },
                getFilterData: {
                    all: function() {
                        return true;
                    },
                    byCategory: el => {
                        if ("undefined" == typeof this.categoria) {
                            return true;
                        }
                        if (this.categoria.id == 0) return true;
                        return -1 != this.categoria.projetos.indexOf(el.id);
                    }
                }
            }
        };
    },
    computed: {
        categoria: {
            get() {
                return this.$store.state.Projetos.categoria;
            },
            set(categoria) {
                this.$store.dispatch("Projetos/setCategoria", categoria);
            }
        },
        slug() {
            return this.$route.params.slug;
        },
        id() {
            if ("undefined" == typeof this.categoria) {
                return 0;
            }
            return this.categoria.id;
        },
        categorias() {
            return this.$store.state.Projetos.categorias;
        },
        projetos() {
            return this.$store.state.Projetos.projetos;
        }
    },
    methods: {
        filtrar(categoria) {
            if ("undefined" == typeof window) {
                return;
            }
            window.isotope = this.$refs.isotope;
            if (categoria.id == 0) {
                this.$refs.isotope.filter("all");
                this.$router.push(`/projetos`);
            } else {
                this.categoria = categoria;
                this.$refs.isotope.filter("byCategory");
                this.$router.push(`/projetos/${categoria.slug}`);
            }
        },
        async setFancybox() {
            if ("undefined" == typeof window) {
                return;
            }
            await import("@/util/fancybox");
            this.$nextTick(() => {
                jQuery(this.$el).off("click", ".fancybox");
                jQuery(this.$el)
                    .find(".fancybox")
                    .fancybox({
                        helpers: {
                            title: {
                                type: "inside"
                            },
                            overlay: {
                                locked: false
                            }
                        },
                        live: true
                    });

                jQuery(this.$el).on("click", ".fancybox", e => {
                    e.preventDefault();
                    jQuery(this).trigger("click.fb");
                });
            });
        }
    },
    updated() {
        this.filtrar(this.categoria);
        this.setFancybox();
    },
    mounted() {
        this.setFancybox();
    },
    async asyncData({ route, store }) {
        await store.dispatch("Projetos/Load");
        if ("undefined" == typeof window) {
            return;
        }
        let slug = route.params.slug;
        if ("undefined" == typeof slug) {
            return;
        }

        await store.dispatch("Projetos/LoadCategoria", slug);
    },
    async created() {
        this.setFancybox();
    }
};
</script>
