<template>
    <div class="lgpd-all" v-if="show">
		<div class="footer-lgpd footer-lgpd-opened">
			<p>
				<strong>Este site informa:</strong> usamos cookies para personalizar anúncios e melhorar a sua experiência no site. Ao continuar navegando, você concorda com a nossa
				<router-link to="/politica-de-privacidade" target="_blank">Política de Privacidade</router-link>.
			</p>
			<button class="lgpd-accept" @click="hide">continuar e fechar</button>
		</div>
	</div>
</template>
<script>
import Cookies from 'js-cookie';
export default {
    data() {
        let show = Cookies.get('lgpd') != 'true';
        
        return {
            show,
        }
    },
    methods: {
        hide() {
            this.show = false;
            Cookies.set('lgpd', true, { path: '/', expires: 365})
        }
    }
}
</script>
