import Vue from "vue";
import App from "./App.vue";
import { createRouter } from "./router";
import { createStore } from "./store";
import { sync } from "vuex-router-sync";

import "@/assets/css/base.less";
import "@/assets/css/style.less";

Vue.config.productionTip = false;

export function createApp() {
	const router = createRouter();
	const store = createStore();

	router.beforeEach((to, from, next) => {
		const path = to.path;
		/*
		let metas = store.state.MetaTags.metas
			.filter(meta => {
				var regex = new RegExp(meta.url, "igm");
				return regex.test(path);
			})
			.reduce((list, meta) => {
				if (!list[meta.name]) {
					list[meta.name] = meta.content;
				}
				return list;
			}, {});

		store.dispatch("MetaTags/Update", metas);
		*/
		store.dispatch("closeMenu");

		next();
	});

	sync(store, router);

	const app = new Vue({
		data: {
			//url: context.url
		},
		router,
		store,
		render: h => h(App)
	});
	return { app, router, store };
}
