import { render, staticRenderFns } from "./Servicos.vue?vue&type=template&id=29ad87e8&"
import script from "./Servicos.vue?vue&type=script&lang=js&"
export * from "./Servicos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "2652813b"
  
)

component.options.__file = "Servicos.vue"
export default component.exports