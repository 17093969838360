import gql from "graphql-tag";
import apollo from "@/graphql";

import config from "@/config";
const endpoint = config.endpoint;

const state = {
	esquerda: "",
	direita: "",
	loading: false
};
const actions = {
	async Load({ commit }) {
		commit("setLoading", true);
		const result = await apollo.query({
			query: gql`
				query BoasPraticas {
					boasPraticas {
						esquerda: texto_esquerda
						direita: texto_direita
					}
				}
			`,
			variables: {}
		});

		commit("setData", result.data.boasPraticas);

		commit("setLoading", false);
	}
};
const mutations = {
	setData(State, { esquerda, direita }) {
		State.esquerda = esquerda;
		State.direita = direita;
	},
	setLoading(State, loading) {
		State.loading = loading;
	}
};
const getters = {};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
};
