<template>
    <li>
        <div :class="{roof:membro.demissao != '' && membro.demissao != '0000-00-00'}">
            <span :style="{'background-image': `url(${membro.background})`}"></span>
            <figure class="image" :style="{'background-image': `url(${membro.foto})`}">
                <img src="@/assets/images/bulma/1by1.png" alt>
            </figure>
            <h4>{{ membro.nome }}</h4>
            <h5>{{ membro.cargo }}</h5>
            <p v-if="membro.demissao == '' || membro.demissao == '0000-00-00'">
                <strong>{{ dias }}</strong> dias somados a equipe
            </p>
        </div>
    </li>
</template>
<script>
export default {
    props: ["membro"],
    computed: {
        demissao() {
            let date = new Date();
            date.setHours(15);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
            if (
                this.membro.demissao != "" &&
                this.membro.demissao != "0000-00-00"
            ) {
                let ano, mes, dia;
                [ano, mes, dia] = this.membro.demissao.split("-");
                date.setFullYear(ano | 0);
                date.setMonth((mes | 0) - 1);
                date.setDate(dia | 0);
            }
            return date;
        },
        admissao() {
            let date = new Date();
            date.setHours(15);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
            if (
                this.membro.admissao != "" &&
                this.membro.admissao != "0000-00-00"
            ) {
                let ano, mes, dia;
                [ano, mes, dia] = this.membro.admissao.split("-");
                date.setFullYear(ano | 0);
                date.setMonth((mes | 0) - 1);
                date.setDate(dia | 0);
            }
            return date;
        },
        dias() {
            const one_day = 3600000 * 24;

            // Convert both dates to milliseconds
            var date1_ms = this.admissao.getTime();
            var date2_ms = this.demissao.getTime();

            // Calculate the difference in milliseconds
            var difference_ms = date2_ms - date1_ms;

            // Convert back to days and return
            return Math.round(difference_ms / one_day);
        }
    }
};
</script>
