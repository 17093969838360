import gql from "graphql-tag";
import apollo from "@/graphql";

import config from "@/config";
const endpoint = config.endpoint;

const state = {
	equipe: {
		texto: ""
	},
	membros: [],
	loading: false
};
const actions = {
	async Load({ commit }) {
		commit("setLoading", true);
		const result = await apollo.query({
			query: gql`
				query Equipe {
					equipe {
						texto
						membros {
							id
							nome
							cargo
							foto
							background
							admissao
							demissao
							ordenamento
						}
					}
				}
			`,
			variables: {}
		});
		result.data.equipe.membros.sort((m1, m2) => {
			return m2.ordenamento - m1.ordenamento;
		});

		const equipe = result.data.equipe;
		const membros = equipe.membros.map(membro => {
			membro.foto = `${endpoint}/media/resize/360x360/${membro.foto}`;
			membro.background = `${endpoint}/media/resize/360x360/${
				membro.background
			}`;
			return membro;
		});

		commit("setEquipe", equipe.texto);
		commit("setMembros", membros);

		commit("setLoading", false);
	}
};
const mutations = {
	setEquipe(State, texto) {
		State.equipe.texto = texto;
	},
	setMembros(State, membros) {
		State.membros = membros;
	},
	setLoading(State, loading) {
		State.loading = loading;
	}
};
const getters = {};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
};
