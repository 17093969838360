import { render, staticRenderFns } from "./HeaderDesktop.vue?vue&type=template&id=6a6d6064&"
import script from "./HeaderDesktop.vue?vue&type=script&lang=js&"
export * from "./HeaderDesktop.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "09d5dc91"
  
)

component.options.__file = "HeaderDesktop.vue"
export default component.exports