<template>
    <body class="home index">
        <div class="wrap-page">
            <HeaderDesktop />
            <!--<Presentation/>-->
            <HeaderMobile />

            <router-view />
        </div>
        <Footer />
        <LGPD></LGPD>
    </body>
</template>
<script>
import Footer from "@/views/layout/Footer.vue";
import LGPD from "@/views/layout/LGPD.vue";
import HeaderDesktop from "@/views/layout/HeaderDesktop.vue";
import HeaderMobile from "@/views/layout/HeaderMobile.vue";
import Presentation from "@/views/layout/Presentation.vue";
export default {
    components: {
        Footer,
        HeaderDesktop,
        HeaderMobile,
        Presentation,
		LGPD
    },
};
</script>
