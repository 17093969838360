import gql from "graphql-tag";
import apollo from "@/graphql";

import config from "@/config";
const endpoint = config.endpoint;

const state = {
	categoria: "",
	search: "",
	ano: 0,
	mes: 0,
	categorias: [],
	arquivos: [],
	posts: [],
	Posts: {},
	post: {},
	pageInfo: {
		endCursor: "",
		hasNextPage: true
	},
	loading: false,
	pageSize: 3
};

const postMap = function postMap(edge) {
	const post = JSON.parse(JSON.stringify(edge.node));
	if (post.imagem) {
		post.imagem.src = `${endpoint}/media/resize/360x360/${post.imagem.src}`;
	}
	if (post.imagens) {
		post.imagens = post.imagens.map(imagem => {
			imagem.src_hd = `${endpoint}/media/${imagem.src}`;
			imagem.src = `${endpoint}/media/resize/360x360/${imagem.src}`;
			return imagem;
		});
	}

	return post;
};

const postsQuery = gql`
	query LoadPosts(
		$after: String
		$pageSize: Int
		$categoria: String
		$ano: Int
		$mes: Int
		$search: String
	) {
		posts: postsBlog(
			first: $pageSize
			after: $after
			category: $categoria
			mes: $mes
			ano: $ano
			search: $search
		) {
			pageInfo {
				endCursor
				hasNextPage
			}
			edges {
				cursor
				node {
					id
					data
					titulo
					slug
					snippet
					texto
					imagem {
						src
						alt
						title
						averageColor
					}
				}
			}
		}
	}
`;

const actions = {
	async LoadPost({ commit, state }, slug) {
		commit("setLoading", true);
		commit("setPost", state.Posts[slug] || {});
		const result = await apollo.query({
			query: gql`
				query loadPost($slug: String) {
					post(slug: $slug) {
						id
						data
						titulo
						slug
						snippet
						texto
						imagens {
							id
							src
							alt
							title
							averageColor
						}
					}
				}
			`,
			variables: {
				slug: slug
			}
		});

		const post = postMap({
			node: result.data.post
		});

		commit("setPost", post);

		commit("setLoading", false);
	},
	async LoadCategorias({ commit }) {
		commit("setLoading", true);

		const result = await apollo.query({
			query: gql`
				query BlogCategory {
					arquivos: arquivosBlog {
						ano
						mes
					}
					categorias: categoriasBlog {
						id
						slug
						titulo
						ordenamento
					}
				}
			`,
			variables: {}
		});

		result.data.arquivos.sort((a, b) => {
			if (a.ano == b.ano) {
				return b.mes - a.mes;
			}
			return b.ano - a.ano;
		});

		commit("setArquivos", result.data.arquivos);
		commit("setCategorias", result.data.categorias);

		commit("setLoading", false);
	},
	async Load({ commit, state }) {
		commit("setLoading", true);
		const result = await apollo.query({
			query: postsQuery,
			variables: {
				pageSize: state.pageSize,
				categoria: state.categoria,
				ano: state.ano,
				mes: state.mes,
				search: state.search
			}
			//fetchPolicy: "no-cache"
		});

		commit("setPageInfo", result.data.posts.pageInfo);

		const posts = result.data.posts.edges.map(edge => postMap(edge));

		commit("setPosts", posts);

		commit("setLoading", false);
	},
	async LoadMore({ commit, state }) {
		commit("setLoading", true);

		const result = await apollo.query({
			query: postsQuery,
			variables: {
				after: state.pageInfo.endCursor,
				pageSize: state.pageSize,
				categoria: state.categoria,
				ano: state.ano,
				mes: state.mes,
				search: state.search
			}
			//fetchPolicy: "no-cache"
		});

		commit("setPageInfo", result.data.posts.pageInfo);

		const posts = result.data.posts.edges.map(edge => postMap(edge));

		commit("setPosts", state.posts.concat(posts));

		commit("setLoading", false);
	},
	setCategoria({ commit }, categoria) {
		commit("setCategoria", categoria);
	},
	setAno({ commit }, ano) {
		commit("setAno", ano);
	},
	setMes({ commit }, mes) {
		commit("setMes", mes);
	},
	setSearch({ commit }, search) {
		commit("setSearch", search);
	},
	setPost({ commit }, post) {
		commit("setPost", post);
	}
};

const mutations = {
	setCategoria(State, categoria) {
		State.pageInfo.endCursor = "";
		State.pageInfo.hasNextPage = true;
		State.categoria = categoria;
	},
	setAno(State, ano) {
		State.pageInfo.endCursor = "";
		State.pageInfo.hasNextPage = true;
		State.ano = ano;
	},
	setMes(State, mes) {
		State.pageInfo.endCursor = "";
		State.pageInfo.hasNextPage = true;
		State.mes = mes;
	},
	setSearch(State, search) {
		State.pageInfo.endCursor = "";
		State.pageInfo.hasNextPage = true;
		State.search = search;
	},
	setArquivos(State, arquivos) {
		State.arquivos = arquivos;
	},
	setCategorias(State, categorias) {
		State.categorias = categorias;
	},
	setPosts(State, posts) {
		State.posts = posts;
		posts.forEach(post => {
			State.post[post.slug] = post;
		});
	},
	setPageInfo(State, pageInfo) {
		State.pageInfo = pageInfo;
	},
	setLoading(State, loading) {
		State.loading = loading;
	},
	setPost(State, post) {
		State.Posts[post.slug] = post;
		State.post = post;
	}
};
const getters = {};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
};
