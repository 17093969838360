import { render, staticRenderFns } from "./LGPD.vue?vue&type=template&id=67675e33&"
import script from "./LGPD.vue?vue&type=script&lang=js&"
export * from "./LGPD.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "5fe5cbbd"
  
)

component.options.__file = "LGPD.vue"
export default component.exports