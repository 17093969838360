<template>
    <div class="contato">
        <section class="title-internal">
            <div class="row">
                <div class="sm-12">
                    <h1>Contato</h1>
                </div>
            </div>
        </section>

        <section class="spacing">
            <div class="row">
                <div class="sm-12 md-6 lg-4 md-centered">
                    <form v-on:submit.prevent.stop="salvar">
                        <input type="text" v-model="contato.nome" placeholder="Nome">
                        <input type="text" v-model="contato.email" placeholder="E-mail">
                        <input type="tel" v-mask="['(##) ####-####', '(##) #####-####']" v-model="contato.telefone" placeholder="Telefone">
                        <textarea v-model="contato.mensagem" placeholder="Mensagem"></textarea>
                        <button v-if="!loading" type="submit" class="default-button">Enviar</button>
                        <button v-else type="submit" disabled class="default-button">Enviando...</button>
                    </form>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { TheMask, mask } from "vue-the-mask";

const IsEmail = {
	validate(str) {
		var regex = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9_](?:[a-zA-Z0-9_\-](?!\.)){0,61}[a-zA-Z0-9_-]?\.)+[a-zA-Z0-9_](?:[a-zA-Z0-9_\-](?!$)){0,61}[a-zA-Z0-9_]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    	return regex.test(str);
	}
};

const defaultContato = {
	nome: "",
	email: "",
	telefone: "",
	mensagem: ""
};

export default {
	components: {
		TheMask
	},
	directives: {
		mask
	},
	data() {
		return {
			contato: {
				nome: "",
				email: "",
				telefone: "",
				mensagem: ""
			}
		};
	},
	computed: {
		loading() {
			return this.$store.state.Contato.loading;
		}
	},
	methods: {
		async salvar() {
			if (this.loading) return false;
			const contato = this.contato;
			try {
				if ("" == contato.nome) {
					throw new Error("Informe o nome.");
				}
				if ("" == contato.email) {
					throw new Error("Informe o e-mail.");
				}
				if (!IsEmail.validate(contato.email)) {
					throw new Error("Informe o e-mail corretamente.");
				}
				const telefone = contato.telefone.replace(/\D/gim, "");
				switch (telefone.length) {
					case 10:
					case 11:
						break;
					case 0:
						if (telefone.length != contato.telefone.length) {
							throw new Error("Informe o telefone corretamente.");
						}
						break;
					default:
						throw new Error("Informe o telefone corretamente.");
						break;
				}
				if ("" == contato.mensagem) {
					throw new Error("Informe a mensagem.");
				}
				await this.$store.dispatch("Contato/Save", contato);
				alert("Formulário enviado com sucesso.");
				this.contato = JSON.parse(JSON.stringify(defaultContato));
			} catch (err) {
				console.log(err);
				alert(err.message);
				return false;
			}
		}
	}
};
</script>
