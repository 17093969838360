<template>
    <section class="tech-home">
		
		<div class="row flex align-center">
			<div class="sm-12 md-6 lg-5" v-html="esquerda">
			</div>
			<div class="sm-12 md-6 offset-lg-1">
				<span v-html="direita"></span>
				<router-link to="/contato" class="default-button translate">Fale com nosso atendimento</router-link>
			</div>
		</div>

	</section>
</template>
<script>
export default {
	created() {
		//this.$store.dispatch("BoasPraticas/Load");
	},
	computed: {
		esquerda() {
			return this.$store.state.BoasPraticas.esquerda;
		},
		direita() {
			return this.$store.state.BoasPraticas.direita;
		}
	}
};
</script>
