import { render, staticRenderFns } from "./Banner.vue?vue&type=template&id=2cd65dec&"
import script from "./Banner.vue?vue&type=script&lang=js&"
export * from "./Banner.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "615a15c5"
  
)

component.options.__file = "Banner.vue"
export default component.exports