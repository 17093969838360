<template>
    <div class="post">
        <section class="title-internal">
            <div class="row">
                <div class="sm-12">
                    <h1 v-if="pagina">{{ pagina.titulo }}</h1>
                </div>
            </div>
        </section>

        <section class="spacing">
            <div class="row">
                <div class="sm-12 md-12">
                    <span v-html="pagina.texto" v-if="pagina"></span>
                    
                </div>
            </div>
        </section>
    </div>
</template>

<script>

export default {
  data() {
    return {
      
    };
  },
  components: {
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
    pagina() {
      return this.$store.state.PaginaAvulsa.pagina || {};
    }
  },
  methods: {
  },
  metas({ store, route }) {
    const pagina = store.state.PaginaAvulsa.Paginas[route.params.slug] || {};
    let description;
    let texto;
    if ("undefined" == typeof window) {
      //const cheerio = await import("cheerio");
      const cheerio = require("cheerio");
      const $ = cheerio.load(pagina.texto);
      texto = $.text();
      description = $("p")
        .eq(0)
        .text();
    } else {
      texto = jQuery(pagina.texto).text();
      description = jQuery(pagina.texto)
        .find("p")
        .eq(0)
        .text();
    }

    const keywords = [
      ...new Set(
        texto
          .replace(/[^\d\w]/gim, " ")
          .replace(/\s+$/gim, "")
          .replace(/^\s+/gim, "")
          .toLowerCase()
          .split(/\s+/gim)
      )
    ];

    const metas = {
      title: `${pagina.titulo} - StudioGT`,
      description: description,
      keywords: keywords.join(",")
    };
    
    return metas;
  },
  async asyncData({ store, route }) {
    await store.dispatch("PaginaAvulsa/LoadPagina", route.params.slug);

  },
  async beforeMount() {
  }
};
</script>
