import gql from "graphql-tag";
import apollo from "@/graphql";

import config from "@/config";
const endpoint = config.endpoint;

const state = {
	categorias: [],
	projetos: [],
	categoria: {
		id: 0,
		titulo: "Todos",
		projetos: []
	},
	loading: false
};
const actions = {
	async Load({ commit }) {
		commit("setLoading", true);
		let result = await apollo.query({
			query: gql`
				query Projetos {
					categorias: categoriasProjetos {
						id
						titulo
						ordenamento
						slug
						projetos {
							id
						}
					}
					projetos {
						id
						imagem
						titulo
						link
						ordenamento
					}
				}
			`,
			variables: {}
		});

		result = JSON.parse(JSON.stringify(result));



		const categorias = result.data.categorias.map(categoria => {
			categoria.projetos = categoria.projetos.map(projeto => {
				return projeto.id;
			});
			return categoria;
		});

		categorias.sort((c1, c2) => {
			return c2.ordenamento - c1.ordenamento;
		});



		const projetos = result.data.projetos.map(projeto => {
			projeto.imagem_hd = `${endpoint}/media/${projeto.imagem}`;
			projeto.imagem = `${endpoint}/media/resize/360x360/${
				projeto.imagem
				}`;

			projeto.imagem = projeto.imagem.replace(
				/^http[s]:\/\/.+(http[s]?:\/\/.+\.(jpg|jpeg|png|gif))/gim,
				"$1"
			);
			return projeto;
		});

		projetos.sort((p1, p2) => {
			return p2.ordenamento - p1.ordenamento;
		});

		commit("setCategorias", categorias);
		commit("setProjetos", projetos);

		commit("setLoading", false);
	},
	async LoadCategoria({ commit, state, dispatch }, slug) {
		await dispatch("Load");
		let categoria = (state.categorias || []).find(cat => {
			return cat.slug == slug;
		});
		commit("setCategoria", categoria);
	},
	setCategoria({ commit }, categoria) {
		commit("setCategoria", categoria);
	}
};
const mutations = {
	setCategorias(State, categorias) {
		State.categorias = categorias;
	},
	setProjetos(State, projetos) {
		State.projetos = projetos;
	},
	setLoading(State, loading) {
		State.loading = loading;
	},
	setCategoria(State, categoria) {
		State.categoria = categoria || {
			id: 0,
			titulo: "Todos",
			slug: "",
			projetos: []
		};
	}
};
const getters = {};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
};
