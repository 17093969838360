import { createApp } from "./create-app";
import Vue from "vue";

import isotope from "vueisotope";
Vue.component("isotope", isotope);

const setMetas = async function({ store, route, metas }) {
	if (metas) {
		metas = "function" === typeof metas ? metas({ store, route }) : metas;
		store.dispatch("MetaTags/Update", metas);
	} else {
		const path = route.path;
		let metas = store.state.MetaTags.metas
			.filter(meta => {
				var regex = new RegExp(meta.url, "igm");
				return regex.test(path);
			})
			.reduce((list, meta) => {
				if (!list[meta.name]) {
					list[meta.name] = meta.content;
				}
				return list;
			}, {});

		store.dispatch("MetaTags/Update", metas);
	}
};

Vue.mixin({
	beforeMount() {
		const { asyncData, metas } = this.$options;
		if (asyncData) {
			this.dataPromise = asyncData({
				store: this.$store,
				route: this.$route
			}).then(() => {
				setMetas({
					store: this.$store,
					route: this.$route,
					metas
				});
			});
		} else {
			setMetas({
				store: this.$store,
				route: this.$route,
				metas
			});
		}
	}
});

Vue.mixin({
	beforeRouteUpdate(to, from, next) {
		const { asyncData, metas } = this.$options;
		if (asyncData) {
			asyncData({
				store: this.$store,
				route: to
			})
				.then(() => {
					setMetas({
						store: this.$store,
						route: to,
						metas
					});
				})
				.then(next)
				.catch(next);
		} else {
			setMetas({
				store: this.$store,
				route: to,
				metas
			});
			next();
		}
	}
});

const { app, router, store } = createApp();
if (window.__INITIAL_STATE__) {
	store.replaceState(window.__INITIAL_STATE__);
}

router.onReady(() => {
	/*
	router.beforeResolve((to, from, next) => {
		const matched = router.getMatchedComponents(to);
		const prevMatched = router.getMatchedComponents(from);

		let diffed = false;
		const activated = matched.filter((c, i) => {
			return diffed || (diffed = prevMatched[i] !== c);
		});

		if (!activated.length) {
			return next();
		}

		Promise.all(
			activated.map(c => {
				if (c.asyncData) {
					return c.asyncData({ store, route: to });
				}
			})
		)
			.then(() => {
				next();
			})
			.catch(next);
	});
	*/
	app.$mount("body");
});
