import { render, staticRenderFns } from "./Presentation.vue?vue&type=template&id=4c4ba35e&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "d0fc2a70"
  
)

component.options.__file = "Presentation.vue"
export default component.exports