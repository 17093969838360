import gql from "graphql-tag";
import apollo from "@/graphql";

import config from "@/config";
const endpoint = config.endpoint;

const state = {
	primarios: [],
	secundarios: [],
	loading: false
};
const actions = {
	async Load({ commit }) {
		commit("setLoading", true);
		const result = await apollo.query({
			query: gql`
				query Banners {
					banners {
						id
						tipo
						titulo
						subtitulo
						imagem
						link
						titulo_link
						posicao_link
						cor_link
						mobile
						desktop
						ordenamento
					}
				}
			`,
			variables: {}
		});
		result.data.banners.sort((b1, b2) => {
			return b2.ordenamento - b1.ordenamento;
		});

		const banners = result.data.banners.map(banner => {
			banner.imagem = `${endpoint}/media/${banner.imagem}`;
			banner.imagem = banner.imagem.replace(
				/^http[s]:\/\/.+(http[s]?:\/\/.+\.(jpg|jpeg|png|gif))/gim,
				"$1"
			);
			if (banner.link == "" || banner.titulo_link == "") {
				banner.tipo_link = "Nenhum";
				banner.posicao_link = "Nenhum";
				if (banner.link == "") {
					banner.link = "javascript:void(0)";
				}
			} else if (/^http[s]?/.test(banner.link)) {
				banner.tipo_link = "Externo";
			} else {
				banner.tipo_link = "Interno";
			}
			if (banner.cor_link == "") {
				banner.cor_link = "#22a6b3";
			}

			return banner;
		});

		const primarios = banners.filter(banner => {
			return banner.tipo == "Principal";
		});
		const secundarios = banners.filter(banner => {
			return banner.tipo == "Secundário";
		});

		commit("setPrimarios", primarios);
		commit("setSecundarios", secundarios);

		commit("setLoading", false);
	}
};
const mutations = {
	setPrimarios(State, banners) {
		State.primarios = banners;
	},
	setSecundarios(State, banners) {
		State.secundarios = banners;
	},
	setLoading(State, loading) {
		State.loading = loading;
	}
};
const getters = {};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
};
