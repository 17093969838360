<template>
    <aside class="widgets">
							
        <form v-on:submit.prevent.stop="doSearch">
            
            <input type="text" name="buscar" placeholder="Buscar" v-model="search">
            <button type="submit"><i class="icon-search"></i></button>
        </form>

        <h3>Categorias</h3>

        <ul>
            
            <li v-for="categoria in categorias" v-bind:key="categoria.id">
                <router-link :to="`/blog/${categoria.slug}`">{{ categoria.titulo }}</router-link>
            </li>
        </ul>

        <h3>Arquivos</h3>

        <ul>
            
            <li v-for="arquivo in arquivos" v-bind:key="arquivo.id">
                <router-link :to="`/blog/${arquivo.ano}/${arquivo.mes}`">{{ arquivo.mes | monthFormatter }} de {{ arquivo.ano }}</router-link>
            </li>
        </ul>
    </aside>
</template>
<script>
export default {
	filters: {
		monthFormatter(mes) {
			const meses = [
				"",
				"Janeiro",
				"Fevereiro",
				"Março",
				"Abril",
				"Maio",
				"Junho",
				"Julho",
				"Agosto",
				"Setembro",
				"Outubro",
				"Novembro",
				"Dezembro"
			];

			return meses[mes | 0];
		}
	},
	data() {
		return {
			search: this.$route.query.q
		};
	},
	methods: {
		doSearch() {
			//if (this.search == "") return;
			if (this.search == "") {
				this.$router.push("/blog");
				return;
			}
			this.$router.push({
				path: "/blog/search",
				query: {
					q: this.search
				}
			});
		}
	},
	computed: {
		categorias() {
			return this.$store.state.Blog.categorias;
		},
		arquivos() {
			return this.$store.state.Blog.arquivos.map(arquivo => {
				arquivo.id = `${arquivo.ano}.${arquivo.mes}`;
				return arquivo;
			});
		}
	},
	created() {
		//this.$store.dispatch("Blog/LoadCategorias");
	}
};
</script>
