<template>
  <div class="servicos">
    <section class="title-internal">
      <div class="row">
        <div class="sm-12">
          <h1>Nossos Serviços</h1>
        </div>
      </div>
    </section>
    <section class="spacing services-list">
      <div class="row">
        <div class="sm-12">
          <ul>
            <li v-for="servico in servicos" v-bind:key="servico.id">
              <!--
                                <router-link :to="`/servico/${servico.slug}`">
                                <div>
                                    <img :src="servico.icone" :alt="servico.titulo">
                                </div>
                                <h2>{{ servico.titulo }}</h2>
                            </router-link>
              -->
              <a>
                <div>
                  <img :src="servico.icone" :alt="servico.titulo">
                </div>
                <h2>{{ servico.titulo }}</h2>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="sm-12">
          <h3>
            Ainda não possui
            <strong>Logotipo</strong> para sua marca? Nós desenvolvemos a sua
            <strong>identidade visual</strong>
          </h3>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  computed: {
    servicos() {
      return this.$store.state.Servicos.servicos;
    }
  },
  asyncData({ store, route }) {
    return store.dispatch("Servicos/Load");
  }
};
</script>
