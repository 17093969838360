import gql from "graphql-tag";
import apollo from "@/graphql";

import config from "@/config";
const endpoint = config.endpoint;

const state = {
	redes: [],
	loading: false
};
const actions = {
	async Load({ commit }) {
		commit("setLoading", true);
		const result = await apollo.query({
			query: gql`
				query RedesSociais {
					redes: redesSociais {
						id
						icone
						titulo
						link
						ordenamento
					}
				}
			`,
			variables: {}
		});
		result.data.redes.sort((r1, r2) => {
			if (r2.destaque == r1.destaque) {
				return r2.ordenamento - r1.ordenamento;
			}
			return r2.destaque - r1.destaque;
		});

		const redes = result.data.redes.map(rede => {
			rede.icone = rede.icone.replace(/^fa\-/gim, "icon-");
			return rede;
		});

		commit("setRedes", redes);

		commit("setLoading", false);
	}
};
const mutations = {
	setRedes(State, redes) {
		State.redes = redes;
	},
	setLoading(State, loading) {
		State.loading = loading;
	}
};
const getters = {};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
};
