<template>
    <section class="spacing about-black">
        <div class="row">
            <div class="sm-12">
                <h2>
                    Timeline da
                    <strong>StudioGT</strong>
                </h2>

                <div class="row">
                    <div class="sm-12 md-12 lg-8 lg-centered">
                        <div class="timeline">
                            <div class="item-sizer"></div>
                            <div class="item">
                                <h4>Início da StudioGT</h4>
                            </div>
                            <div class="item" v-for="item in itens" v-bind:key="item.ano">
                                <h3>{{ item.ano }}</h3>
                                <div v-html="item.texto"></div>
                                <img :src="item.full_imagem" alt>
                            </div>
                            <div class="item">
                                <h4>VEM FAZER PARTE DESSA HISTÓRIA</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import config from "@/config.js";

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

const mounted = debounce(async function(ctx) {
    console.log(49);
        //this.$nextTick(async () => {
    try {
        console.log(52);
        const promises = ctx.itens.filter(item => {
            return item.imagem != '' && item.imagem != null;
        }).map(item => {
            return new Promise((resolve, reject) => {
                console.log('imagem: ',item.full_imagem, item.imagem);

                const img = new Image();
                img.onload = function() {
                    console.log('onload', this);
                    resolve(this);
                };
                img.onerror = function() {
                    console.log('onerror', this);
                    reject(this);
                };

                img.src = item.full_imagem;
            });
        });
        console.log(63);
        await Promise.all(promises);
        console.log(68);
    } catch (err) {
        console.log(err);
    }

    const Masonry = require("masonry-layout");

    const updateMansory = () => {
        console.log(76);
        //setTimeout(() => {
            //console.log("updateMansory");
            console.log(79);
            var $el = ctx.$el.querySelector(".timeline");
            if (ctx.msnry) {
                console.log(82);
                ctx.msnry.destroy();
            }
            console.log(85);
            ctx.msnry = new Masonry($el, {
                itemSelector: ".item",
                gutter: ".item-sizer"
            });
            console.log(90);
        //}, 200);
    };

    if (document.readyState == "loading") {
        console.log(95);
        document.addEventListener("DOMContentLoaded", updateMansory);
    } else {
        console.log(98);
        updateMansory();
    }
}, 250);

export default {
    computed: {
        itens() {
            /*
            let itens = this.$store.state.Sobre.timeline;
            itens.sort((a, b) => {
                return a.ano < b.ano ? -1 : 1;
            });
            return itens;
            */
           let itens = this.$store.state.Sobre.timeline.map((item) => {
               return {
                   ano: item.ano|0,
                   texto: item.texto+'',
                   full_imagem: item.full_imagem+'',
                   imagem: item.imagem
               };
           });
           itens.sort((a, b) => {
               return a.ano < b.ano ? -1 : 1;
           });
           
           return itens;
        }
    },
    mounted() {
        if ("undefined" == typeof window) {
            return;
        }
        
        mounted(this);
        
       // });
    },
    updated() {
        if ("undefined" == typeof window) {
            return;
        }

        
        /*
        this.$nextTick(async () => {
            try {
                console.log(109);
                const promises = this.itens.map(item => {
                    return new Promise((resolve, reject) => {
                        const img = new Image();
                        img.onload = function() {
                            resolve(this);
                        };
                        img.onerror = function() {
                            reject(this);
                        };

                        img.src = item.full_imagem;
                    });
                });
                console.log(123);
                await Promise.all(promises);
                console.log(125);
            } catch (err) {
                console.log(127);
                console.log(err);
            }

            const Masonry = require("masonry-layout");
            console.log(132);
            const updateMansory = () => {
                console.log(134);
                setTimeout(() => {
                    console.log(136);
                    //console.log("updateMansory");
                    var $el = this.$el.querySelector(".timeline");
                    if (this.msnry) {
                        console.log(140);
                        this.msnry.destroy();
                    }
                    console.log(143);
                    this.msnry = new Masonry($el, {
                        itemSelector: ".item",
                        gutter: ".item-sizer"
                    });
                    console.log(148);
                }, 200);
                console.log(150);
            };

            if (document.readyState == "loading") {
                console.log(154);
                document.addEventListener("DOMContentLoaded", updateMansory);
            } else {
                console.log(157);
                updateMansory();
            }
        });
        */
    }
};
</script>
