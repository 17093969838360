import { render, staticRenderFns } from "./Projetos.vue?vue&type=template&id=b1b00822&"
import script from "./Projetos.vue?vue&type=script&lang=js&"
export * from "./Projetos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "9ae34d52"
  
)

component.options.__file = "Projetos.vue"
export default component.exports