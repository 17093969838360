<template>
    <header class="hidden-lg wrap-header-mobile">

        <div class="row">
            <div class="sm-2 md-1 flex-centered">
                <a href="javascript:void(0);" class="toggle-menu" v-on:click="toggleMenu">
                    <i class="icon-bars"></i>
                </a>
            </div>
            <div class="sm-8 md-10">
                <Logo />
            </div>
        </div>

        <div class="mobile-menu" v-bind:class="{ opened: opened}">
            <div class="row">
                <div class="sm-2 md-1 flex-centered">
                    <a href="javascript:void(0);" class="toggle-menu" v-on:click="toggleMenu">
                        <i class="icon-close"></i>
                    </a>
                </div>
                <div class="sm-12">
                    <Menu />
                </div>
            </div>
        </div>
    </header>
</template>
<script>
import Logo from "@/views/layout/Logo.vue";
import Menu from "@/views/layout/Menu.vue";
export default {
	computed: {
		opened() {
			return this.$store.state.menu.opened;
		}
	},
	methods: {
		toggleMenu() {
			this.$store.dispatch("toggleMenu");
		}
	},
	components: {
		Logo,
		Menu
	}
};
</script>
