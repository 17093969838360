<template>
    <div class="sobre">
        <section class="title-internal">
            <div class="row">
                <div class="sm-12">
                    <h1>Somos uma <strong>Agência Digital</strong> focada em soluções</h1>
                </div>
            </div>
        </section>
        <section class="spacing about">
            <div class="row flex align-center">
                <div class="sm-12 md-4">
                    <span class="detail"></span>
                    <div v-html="esquerda"></div>
                </div>
                <div class="sm-12 md-4">
                    <img src="@/assets/images/about-orni.png" alt="">
                </div>
                <div class="sm-12 md-4">
                    <div v-html="direita"></div>
                </div>
            </div>
        </section>
        <Timeline />
    </div>
</template>
<script>
import Timeline from "@/views/sobre/Timeline.vue";
export default {
	components: {
		Timeline
	},
	computed: {
		esquerda() {
			return this.$store.state.Sobre.esquerda;
		},
		direita() {
			return this.$store.state.Sobre.direita;
		}
	},
	asyncData({ store, route }) {
		return store.dispatch("Sobre/Load");
	}
};
</script>

